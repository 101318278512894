import { cn } from "@/lib/utils";
import { cva, VariantProps } from "class-variance-authority";
import { ComponentPropsWithRef, forwardRef, ReactNode } from "react";

interface ButtonBaseProps {
  prefix?: ReactNode;
  suffix?: ReactNode;
  icon?: ReactNode;
  prefixStyles?: string;
  suffixStyles?: string;
}

export interface ButtonProps
  extends Omit<ComponentPropsWithRef<"button">, keyof ButtonBaseProps>,
    ButtonBaseProps,
    Omit<VariantProps<typeof buttonVariants>, "disabled"> {}

export const buttonVariants = cva(
  "whitespace-nowrap disabled:bg-disabled text-default text-bodyMd disabled:border-default focus:ring-focus disabled:text-disabled flex h-9 items-center justify-center gap-2 rounded-lg border px-4 py-2 font-medium  transition-colors duration-150 ease-in-out focus:outline-none focus:ring-2 [&_svg]:h-4 [&_svg]:w-4",
  {
    variants: {
      variant: {
        basic:
          "bg-primary hover:bg-primary-hovered active:bg-primary-pressed border-default text-on-primary",
        outline: "active:bg-pressed bg-default border-default hover:bg-hovered",
        plain:
          "active:bg-pressed hover:bg-hovered hover:border-default border-[transparent] bg-[transparent]",
        // use in place of select trigger
        trigger:
          "active:bg-pressed  bg-default border-default hover:bg-hovered focus:ring-focus focus:ring-2 font-normal",
        link: "h-auto border-0 p-0 focus:outline-0 focus:ring-0 focus-visible:ring-0",
      },

      theme: {
        default: "",
        critical:
          "bg-critical active:bg-critical-pressed hover:bg-critical-hovered border-critical text-on-primary",
        warning:
          "bg-warning active:bg-warning-pressed hover:bg-warning-hovered border-warning text-on-primary",
      },

      fullWidth: {
        true: "w-full",
        false: "w-max",
      },
      size: {
        icon: "h-6 w-6",
        iconSm: "h-4 w-4",
      },
      disabled: {
        true: "opacity-50 pointer-events-none",
      },
    },
    compoundVariants: [
      {
        variant: "basic",
        theme: "critical",
        className: "bg-critical",
      },
      {
        variant: "outline",
        theme: "critical",
        className:
          "bg-critical-subdued hover:bg-critical-subdued active:bg-surface outline-critical text-critical",
      },
      {
        variant: "outline",
        theme: "warning",
        className:
          "bg-warning-subdued hover:bg-warning-subdued active:bg-surface outline-warning text-warning",
      },
      {
        variant: "plain",
        theme: "critical",
        className:
          "hover:bg-critical-subdued active:bg-surface outline-critical text-critical border-[transparent] bg-[transparent]",
      },
    ],
    defaultVariants: {
      variant: "basic",
      theme: "default",
      fullWidth: false,
    },
  },
);

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    children,
    prefix,
    suffix,
    icon,
    variant = "basic",
    theme,
    fullWidth,
    className = "",
    size,
    prefixStyles = "",
    suffixStyles = "",
    ...restProps
  } = props;

  if (icon) {
    return (
      <button
        type="button"
        ref={ref}
        className={cn(
          buttonVariants({
            variant,
            theme,
            fullWidth,
            size: size ? size : "icon",
          }),
          "p-0",
          className,
        )}
        {...restProps}
      >
        {icon}
      </button>
    );
  }

  return (
    <button
      type="button"
      className={cn(buttonVariants({ variant, theme, fullWidth }), className)}
      ref={ref}
      {...restProps}
    >
      {prefix && <span className={cn("shrink-0", prefixStyles)}>{prefix}</span>}
      {children}
      {suffix && <span className={cn("shrink-0", suffixStyles)}>{suffix}</span>}
    </button>
  );
});

export { Button };
