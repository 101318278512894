"use client";

import { cn } from "@/lib/utils";
import { Check, ChevronRight } from "@jengaicons/react";
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import * as React from "react";

import { ScrollArea } from "./scrollarea";

const transitions = {
  dropdownContent: `data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2`,
};
const DropdownMenu = DropdownMenuPrimitive.Root;

function DropdownMenuTrigger(
  props: React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Trigger>,
) {
  const { children, className, ...restProps } = props;
  return (
    <DropdownMenuPrimitive.Trigger
      className={cn("data-[state=open]:bg-pressed", className)}
      {...restProps}
    >
      {children}
    </DropdownMenuPrimitive.Trigger>
  );
}

DropdownMenuTrigger.displayName = DropdownMenuPrimitive.Trigger.displayName;

function DropdownMenuGroup(
  props: React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Group>,
) {
  const { children, className, ...restProps } = props;
  return (
    <DropdownMenuPrimitive.DropdownMenuGroup
      className={cn("space-y-1", className)}
      {...restProps}
    >
      {children}
    </DropdownMenuPrimitive.DropdownMenuGroup>
  );
}

const DropdownMenuPortal = DropdownMenuPrimitive.Portal;

const DropdownMenuSub = DropdownMenuPrimitive.Sub;

const DropdownMenuRadioGroup = DropdownMenuPrimitive.RadioGroup;

const DropdownMenuCheckboxItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.CheckboxItem>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.CheckboxItem>
>(({ className, children, checked, ...props }, ref) => (
  <DropdownMenuPrimitive.CheckboxItem
    ref={ref}
    className={cn(
      "focus:bg-hovered relative flex cursor-default select-none items-center rounded-sm px-4 py-2 text-sm outline-none transition-colors data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
      className,
    )}
    checked={checked}
    {...props}
  >
    {children}
    <span className="absolute right-4 flex h-4 w-4 items-center justify-center">
      <DropdownMenuPrimitive.ItemIndicator>
        <Check className="data-[state=checked]:bg-primary data-[state=checked]:text-on-primary h-3 w-3" />
      </DropdownMenuPrimitive.ItemIndicator>
    </span>
  </DropdownMenuPrimitive.CheckboxItem>
));
DropdownMenuCheckboxItem.displayName =
  DropdownMenuPrimitive.CheckboxItem.displayName;

function DropdownMenuRadioItem(
  props: React.ComponentPropsWithoutRef<
    typeof DropdownMenuPrimitive.DropdownMenuRadioItem
  >,
) {
  const { children, className, ...restProps } = props;
  return (
    <DropdownMenuPrimitive.RadioItem
      className={cn(
        "hover:bg-hovered focus:bg-hovered mx-1 flex items-center gap-3 rounded-lg px-4 py-2 focus:outline-none",
        className,
      )}
      {...restProps}
    >
      <div className="focus-visible:ring-focus border-primary text-primary focus-visible:ring-offset-3 flex aspect-square h-4 w-4 items-center justify-center gap-2 rounded-full border focus:outline-none focus-visible:ring-2 disabled:cursor-not-allowed disabled:opacity-50">
        <DropdownMenuPrimitive.ItemIndicator className="border-primary rounded-full border p-0.5">
          <div className="bg-primary block h-3 w-3 rounded-full"></div>
        </DropdownMenuPrimitive.ItemIndicator>
      </div>
      <span>{children}</span>
    </DropdownMenuPrimitive.RadioItem>
  );
}

DropdownMenuRadioItem.displayName =
  DropdownMenuPrimitive.DropdownMenuRadioItem.displayName;

const DropdownMenuSubTrigger = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.SubTrigger>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.SubTrigger> & {
    inset?: boolean;
    noIcon?: boolean;
  }
>(({ className, noIcon = false, inset, children, ...props }, ref) => (
  <DropdownMenuPrimitive.SubTrigger
    ref={ref}
    className={cn(
      "hover:bg-hovered data-[state=open]:bg-hovered mx-1 flex cursor-default select-none items-center rounded-lg px-4 py-2 outline-none",
      inset && "pl-8",
      className,
    )}
    {...props}
  >
    {children}
    {!noIcon && <ChevronRight className="ml-auto h-4 w-4" />}
  </DropdownMenuPrimitive.SubTrigger>
));
DropdownMenuSubTrigger.displayName =
  DropdownMenuPrimitive.SubTrigger.displayName;

const DropdownMenuSubContent = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.SubContent>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.SubContent>
>(({ className, ...props }, ref) => (
  <DropdownMenuPrimitive.SubContent
    ref={ref}
    className={cn(
      "bg-default text-default shadow-0.25 z-[10000] min-w-[8rem] space-y-1 overflow-hidden rounded-lg border py-1",
      transitions.dropdownContent,
      className,
    )}
    {...props}
  />
));
DropdownMenuSubContent.displayName =
  DropdownMenuPrimitive.SubContent.displayName;

const DropdownMenuContent = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Content>
>(({ className, sideOffset = 4, children, ...props }, ref) => (
  <DropdownMenuPrimitive.Portal>
    <DropdownMenuPrimitive.Content
      sticky="partial"
      ref={ref}
      sideOffset={sideOffset}
      className={cn(
        "border-default bg-default text shadow-0.25 relative z-[10000] min-w-[8rem] space-y-1 overflow-hidden rounded-lg border py-1",
        transitions.dropdownContent,
        className,
      )}
      {...props}
    >
      {children}
    </DropdownMenuPrimitive.Content>
  </DropdownMenuPrimitive.Portal>
));
DropdownMenuContent.displayName = DropdownMenuPrimitive.Content.displayName;

const DropdownMenuItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item> & {
    inset?: boolean;
  }
>(({ className, inset, ...props }, ref) => (
  <DropdownMenuPrimitive.Item
    ref={ref}
    className={cn(
      "text-bodyMd hover:bg-hovered focus:bg-hovered relative mx-1 flex cursor-pointer select-none items-center rounded-lg px-4 py-2 outline-none transition-colors data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
      inset && "pl-8",
      className,
    )}
    {...props}
  />
));
DropdownMenuItem.displayName = DropdownMenuPrimitive.Item.displayName;

const DropdownMenuLabel = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Label> & {
    inset?: boolean;
  }
>(({ className, inset, ...props }, ref) => (
  <DropdownMenuPrimitive.Label
    ref={ref}
    className={cn("text-bodySm text-disabled px-4 py-2", className)}
    {...props}
  />
));
DropdownMenuLabel.displayName = DropdownMenuPrimitive.Label.displayName;

const DropdownMenuSeparator = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <DropdownMenuPrimitive.Separator
    ref={ref}
    className={cn("bg-active -mx-1 my-2 h-px", className)}
    {...props}
  />
));
DropdownMenuSeparator.displayName = DropdownMenuPrimitive.Separator.displayName;

const MenuShortcut = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLSpanElement>) => {
  return (
    <span
      className={cn("ml-auto text-xs tracking-widest opacity-60", className)}
      {...props}
    />
  );
};
MenuShortcut.displayName = "MenuShortcut";

export {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuGroup,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuCheckboxItem,
  MenuShortcut as DropdownMenuShortcut,
};
