const {
  COLORS,
  SHADOWS,
  TEXT_SIZE,
  LINE_HEIGHT,
} = require("./lib/constants/outpost-design");

/** @type {import('tailwindcss').Config} */
module.exports = {
  darkMode: ["class"],
  content: [
    "./app/**/*.{ts,tsx}",
    "./dashboard/**/*.{ts,tsx}",
    "./mdx-components.tsx",
    "./components/**/*.{ts,tsx}",
    "./src/**/*.{ts,tsx}",
  ],
  theme: {
    colors: {
      ...COLORS.GENERIC,
    },
    textColor: {
      ...COLORS.TEXT,
    },
    backgroundColor: {
      ...COLORS.BACKGROUND,
    },
    borderColor: {
      ...COLORS.BORDER,
    },
    divideColor: {
      ...COLORS.DIVIDE,
    },
    ringColor: {
      ...COLORS.RING,
    },
    outlineColor: {
      ...COLORS.OUTLINE,
    },
    boxShadow: {
      ...SHADOWS,
    },
    fontSize: {
      ...TEXT_SIZE,
    },
    lineHeight: {
      ...LINE_HEIGHT,
    },
    extend: {
      fontFamily: {
        sans: ["var(--font-sans)"],
      },
      keyframes: {
        "accordion-down": {
          from: { height: 0 },
          to: { height: "var(--radix-accordion-content-height)" },
        },
        "accordion-up": {
          from: { height: "var(--radix-accordion-content-height)" },
          to: { height: 0 },
        },
        "scroll-left": {
          "0%": { transform: "translateX(0)" },
          "100%": { transform: "translateX(-100%)" },
        },
        "scroll-right": {
          "0%": { transform: "translateX(-100%)" },
          "100%": { transform: "translateX(0)" },
        },

        marquee: {
          from: { transform: "translateX(0)" },
          to: { transform: "translateX(calc(-100% - var(--gap)))" },
        },
        "marquee-vertical": {
          from: { transform: "translateY(0)" },
          to: { transform: "translateY(calc(-100% - var(--gap)))" },
        },
      },
      animation: {
        "scroll-left": "scroll-left 120s linear infinite",
        "scroll-right": "scroll-right 120s linear infinite",
        "scroll-bottom": "scroll-bottom 60s linear infinite",
        "scroll-top": "scroll-top 60s linear infinite",
        "accordion-down": "accordion-down 0.2s linear",
        "accordion-up": "accordion-up 0.2s linear",
        marquee: "marquee var(--duration) linear infinite",
        "marquee-vertical": "marquee-vertical var(--duration) linear infinite",
      },
      container: {
        center: true,
      },
      screens: {
        xs: "480px",
        "3xl": "1920px",
      },
    },
  },

  plugins: [require("tailwindcss-animate"), require("tailwind-scrollbar")],
};
